import { SelectChangeEvent } from "@mui/material";
import { PieChartWithLeftLegend } from "../../../shared"
import { DropdownItem } from "../../../shared/drop-down";
import { SharedConstants, SharedRedux } from "@mprofit/shared";
import { PdfHoldingSnapshotDataProps } from "../../../shared/asset-allocation-pie-chart/pie-chart-left-legend";
import { useAppSelector } from "packages/web/src/redux/hooks";

interface SubColumsProps {
  FieldKey: string;
  ColumnWidthPercent?: number | string;
  Align?: string;
  FieldFormatter: (value: string | number | boolean | null, dec?: number, sep?: string) => React.ReactNode;
  UpFieldKey?: string;
}

export interface ColumnDataProps {
  FieldKey: string;
  HeaderName: string;
  ColumnWidthPercent?: number | string;
  Align?: string;
  FieldFormatter: (value: string | number | boolean | null, dec?: number, sep?: string) => React.ReactNode;
  subColumns?: SubColumsProps[];
  IsRowName?: boolean;
  IsString?: boolean;
}

export interface RowDataProps {
  [key: string]: string | number | boolean | null;
}

interface HoldingAssetAllocationChartProps {
    data: any[];
    totalValue: string | undefined;
    heading: string;
    subHeading: string;
    dropdownValue: SharedConstants.HoldingAssetAllocationDropdownValue,
    handleDropdownChange?: (selected: DropdownItem) => void,
    onItemClick?: (item: SharedConstants.PieChartData) => void,
    hideDropdown?: boolean;
    showDownloadPdfIcon?: boolean;
    pdfData?: PdfHoldingSnapshotDataProps;
    holdingsMode?: SharedConstants.HoldingsMode;
    tableType?: SharedConstants.TableType;
  }

export const HoldingAssetAllocationChart = (props: HoldingAssetAllocationChartProps) => {
  const isPriceRefreshedOnce = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectPriceValuesIsRefreshedOnce);

  return <PieChartWithLeftLegend
    {...props}
    dropdownList={props.hideDropdown === true ? undefined : dropdownList}
    totalFieldLabel={dropdownList.find(x => x.id == props.dropdownValue)?.name}
    handleDropdownChange={props.handleDropdownChange || (()=>{})}
    onItemClick={props.onItemClick}
    showDownloadPdfIcon={props.showDownloadPdfIcon}
    pdfData={isPriceRefreshedOnce ? props.pdfData : undefined}
    holdingsMode={props.holdingsMode}
    tableType={props.tableType || SharedConstants.TableType.Holding}
  />
}

const dropdownList = [{id: 'CurrValue', name: 'Current Value'}, {id: 'InvAmt', name: 'Amount Invested'}]; 