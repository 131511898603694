import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DownSortIcon } from 'packages/web/src/assets/icons/DownSortIcon';
import { UpSortIcon } from 'packages/web/src/assets/icons/UpSortIcon';
import { SortingIcon } from 'packages/web/src/assets/icons/SortingIcon';
import { TableIconDown } from '../../../assets/icons/TableIconDown';
import { TableVirtuoso } from 'react-virtuoso';
import CustomTypography from '../customTypography';
import { isZeroOrPositive } from '../../../../../shared/src/utilities';
import { SharedUtilities, formatDecimal, formatPercent, SharedConstants, SharedRedux } from '@mprofit/shared';
import { TableIconUp } from '../../../assets/icons/TableIconUp';
import { forwardRef, useEffect, useState, useRef } from 'react';
import Big from "big.js";
import PMSBadge from '../pms-badge';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import { SortConfig, TableType } from 'packages/shared/src/constants';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';


export interface ColumnDataProps {
  FieldKey: string;
  HeaderName: string;
  ColumnWidthPercent?: number | string;
  Align?: 'left' | 'right' | 'center';
  FieldFormatter: (value: any, dec?: number, sep?: string) => React.ReactNode;
  subColumns?: SubColumsProps[];
  IsRowName?: boolean;
  IsString?: boolean;
}

export const TableDefaultFieldFormatter = (value: any) => value;
export const TableBoldFieldFormatter = (value: any) => <strong>{value}</strong>;
export const TableDecimalFieldFormatter = (value: any, dec?: number, sep?: string) => formatDecimal(value, dec || 2, sep || 'IN', false, false, false, false, false, true);
export const TablePercentFieldFormatter = (value: any, dec?: number, sep?: string) => formatPercent(value, dec || 2, sep || 'IN');

interface SubColumsProps {
  FieldKey: string;
  ColumnWidthPercent?: number;
  Align?: 'left' | 'right' | 'center';
  FieldFormatter: (value: any, dec?: number, sep?: string) => React.ReactNode;
  UpFieldKey?: string;
}

export interface RowDataProps {
  [key: string]: any;
}

interface Props {
  columns: ColumnDataProps[];
  rows: RowDataProps[];
  rowHeight?: number;
  headerColor?: string;
  tableHeight?: string;
  sorting?: boolean;
  isScrollable?: boolean;
  onRowClick?: (row: RowDataProps) => void;
  searchTerm?: string;
  fieldNamesToSearch?: string[];
  defaultSortConfig?: SortConfig;
  rowClickDisabled?: boolean;
  PMSBadgeField?: string;
  tableType?: TableType;
  tableMode?: SharedConstants.AdvancedPerformanceTableMode;
}

const SharedVirtualizedTable: React.FC<Props> = ({ columns, rows, headerColor, tableHeight, sorting, isScrollable = true, onRowClick, searchTerm, fieldNamesToSearch, defaultSortConfig, rowClickDisabled, PMSBadgeField, tableType, tableMode }) => {
  const dispatch = useAppDispatch();
  const tableConfig = useAppSelector(SharedRedux.Dashboard.Selectors.selectTableConfig(tableType!));

  const rootRef = useRef(null);
  const [scroller, setScroller] = useState<HTMLElement | null>(null);
  const [initialize, osInstance] = useOverlayScrollbars({ options: { scrollbars: { autoHide: 'scroll' } }, defer: true });

  useEffect(() => {
    const headerColName = columns.find(column => column.IsRowName);
    if (headerColName && tableType && defaultSortConfig && !tableConfig) {
      dispatch(SharedRedux.Dashboard.Actions.initializeTableConfig({tableType, sortConfig: { key: defaultSortConfig.key, direction: defaultSortConfig.direction, isRowName: headerColName.IsRowName }, tableMode}));
    }

    const sortKey = tableConfig?.sortConfig.key;
    const doesSortColumnExist = columns.some(column => column.FieldKey === sortKey);
    console.log("🚀 ~ useEffect ~ doesSortColumnExist:", tableConfig, sortKey, doesSortColumnExist)
    if (tableType && sortKey && !doesSortColumnExist) {
      if(headerColName) {
        if (tableConfig?.sortConfig.isRowName === true) {
          dispatch(SharedRedux.Dashboard.Actions.setTableConfig({tableType, sortConfig: { key: headerColName.FieldKey, direction: tableConfig.sortConfig.direction, isRowName: headerColName.IsRowName }, tableMode}));
        } else if (defaultSortConfig) {
          dispatch(SharedRedux.Dashboard.Actions.setTableConfig({tableType, sortConfig: { key: defaultSortConfig.key, direction: defaultSortConfig.direction, isRowName: headerColName.IsRowName }, tableMode}));
        }
      }
    }

  }, [columns]);
  
  useEffect(()=> {
    if(tableMode && tableType) {
      dispatch(SharedRedux.Dashboard.Actions.setTableConfigMode({tableType, tableMode}));
    }
  }, [tableMode]);

  const handleSorting = (key: string) => {
    var column = columns.find(x => x.FieldKey === key);

    var isColumnNumeric = column && ((column.IsRowName || column.IsString) !== true);
    let direction: SharedConstants.SortDirection = isColumnNumeric ? SharedConstants.SortDirection.Down : SharedConstants.SortDirection.Up;
    if (
      tableConfig &&
      tableConfig.sortConfig.key === key
    ) {
      direction = (tableConfig?.sortConfig.direction || direction) * -1;
    }

    if (tableType) {
      dispatch(SharedRedux.Dashboard.Actions.setTableConfig({tableType, sortConfig: { key, direction, isRowName: column?.IsRowName }, tableMode}));
    }
  };

  const [finalRows, setFinalRows] = useState<RowDataProps[]>(rows);

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, activeRow: RowDataProps) => {
    if (onRowClick) {
      onRowClick(activeRow);
    }
  }

  useEffect(() => {
    setFinalRows(sortRows(SharedUtilities.filterItems(searchTerm, rows, fieldNamesToSearch), columns, tableConfig?.sortConfig.key, tableConfig?.sortConfig.direction));
  }, [searchTerm, rows, tableConfig, columns, fieldNamesToSearch])

  useEffect(() => {
    const { current: root } = rootRef;

    if (scroller && root) {
      initialize({
        target: root,
        elements: {
          viewport: scroller,
        },
      });
    }

    return () => osInstance()?.destroy();
  }, [scroller, initialize, osInstance]);

  return (
    <Box data-overlayscrollbars-initialize="" ref={rootRef}>
      <TableVirtuoso
        scrollerRef={(ref) => {
          if (ref instanceof HTMLElement) {
            setScroller(ref);
          }
        }}
        style={{
          background: '#FFFFFF', width: '100%', minHeight: tableHeight ? tableHeight : '400px', overflowY: isScrollable ? "scroll" : "hidden"
        }}
        components={{
          Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
          TableHead: TableHead,
          TableRow: (props) => <TableRow onClick={(event) => rowClickDisabled ? undefined : handleRowClick(event, props.item)} {...props} sx={rowClickDisabled ? {} : {
            ":hover": { background: '#F9F9F9' },
            '.IsRowName div': {
              display: 'inline-block'
            },
            ':hover .IsRowName div': {
              color: '#5F854C',
              fontWeight: 500,
              cursor: 'pointer',
            },
            "cursor": "pointer"
          }} />,
          TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
        }}
        data={finalRows}
        fixedHeaderContent={() => (
          <TableRow sx={{ background: headerColor ? headerColor : "white" }}>
            {columns.map((column) => (
              <TableCell key={column.FieldKey} align={column.Align} sx={{ width: column.ColumnWidthPercent, maxWidth: column.ColumnWidthPercent, textTransform: 'uppercase' }}>
                <Box sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'inherit' }}>
                  <Box sx={{ marginRight: '8px', cursor: 'pointer' }} onClick={() => handleSorting(column.FieldKey)}>
                      <CustomTypography variant='Medium14'>{column.HeaderName}</CustomTypography>
                  </Box>
                  {sorting && tableConfig?.sortConfig.key === column.FieldKey ? (
                      <Box sx={{ cursor: 'pointer', width: '10px', height: '20px' }} onClick={() => handleSorting(column.FieldKey)}>
                          {tableConfig.sortConfig.direction === SharedConstants.SortDirection.Down ? (
                              <DownSortIcon />
                          ) : (
                              <UpSortIcon />
                          )}
                      </Box>
                  ) : (
                      <Box sx={{ cursor: 'pointer', width: '10px', height: '20px' }} onClick={() => handleSorting(column.FieldKey)}>
                          <Box sx={{marginLeft: '-3px', marginTop: '1.5px'}}>
                              <SortingIcon />
                          </Box>
                      </Box>
                  )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(index, data) => {
          return (
            <SharedTableRow {...{ columns, row: data, isLastRow: index === finalRows.length - 1, onRowClick, PMSBadgeField }} />
          )
        }}
      />
    </Box>
  );
};

const SharedTableRow = ({ columns, row, isLastRow, onRowClick, PMSBadgeField }: { columns: ColumnDataProps[], row: RowDataProps, isLastRow: boolean, onRowClick?: (row: RowDataProps) => void, PMSBadgeField?: string }) => {
  const borderBottom = isLastRow ? 'none' : '1px solid #EBECF2';
  return (
    <>
      {
        columns.map((column, index) => {
          var showRowNameClass = column.IsRowName && onRowClick;
          if (column.subColumns) {
            return column.subColumns.map((subColumn) => (
              <TableCell className={showRowNameClass ? "IsRowName" : ""} key={subColumn.FieldKey} align={column.Align} sx={{ width: column.ColumnWidthPercent, maxWidth: column.ColumnWidthPercent, borderBottom, minWidth: "125px" }} >
                <CustomTypography variant='Regular18'>{column.FieldFormatter(row[column.FieldKey])}</CustomTypography>
                {row[subColumn.FieldKey] &&
                  <Box>
                    {(subColumn.UpFieldKey ? row[subColumn.UpFieldKey] === true : isZeroOrPositive(row[subColumn.FieldKey])) ?
                      <CustomTypography sx={{ color: { xs: "#5F854C", display: 'inline-flex', alignItems: 'center', gap: '4px' } }} >
                        {subColumn.FieldFormatter(row[subColumn.FieldKey])}
                        <TableIconUp />
                      </CustomTypography>
                      :
                      <CustomTypography sx={{ color: { xs: '#C94C40', display: 'inline-flex', alignItems: 'center', gap: '4px' } }} >
                        {subColumn.FieldFormatter(row[subColumn.FieldKey])}
                        <TableIconDown />
                      </CustomTypography>
                    }
                  </Box>
                }
              </TableCell >
            ))
          }
          else {
            return (
              <TableCell className={showRowNameClass ? "IsRowName" : ""} key={column.FieldKey} align={column.Align} sx={{
                width: column.ColumnWidthPercent, maxWidth: column.ColumnWidthPercent, borderBottom, minWidth: "125px" 
              }}>
                <Box display="flex" justifyContent={column.Align}>
                  <CustomTypography variant='Regular18' > {column.FieldFormatter(row[column.FieldKey])}</CustomTypography>
                  {
                    column.IsRowName && PMSBadgeField && row[PMSBadgeField] ? <Box ml={1}><PMSBadge badgeStyles={{ color: '#000', borderColor: '#000', backgroundColor: '#E2DA90', padding: '1px 6px' }} /></Box> : null
                  }
                </Box>
              </TableCell >
            )
          }
        })
      }
    </>
  );
};

export const sortRows = (rows: RowDataProps[], columns: ColumnDataProps[], sortKey?: string, direction?: SharedConstants.SortDirection) => {
  if (!rows || !sortKey) return rows;

  var column = columns.find(x => x.FieldKey === sortKey);

  if (!column) return rows;

  var isColumnNumeric = (column.IsRowName || column.IsString) !== true;

  try {
    return [...rows].sort((x, y) => ((isColumnNumeric ? Big(x[sortKey] || 0).lte(y[sortKey] || 0) : ((x[sortKey] || '').toLowerCase() < (y[sortKey] || '').toLowerCase())) ? -1 : 1) * (direction === SharedConstants.SortDirection.Down ? -1 : 1));
  } catch {
    return rows;
  }
}

export default SharedVirtualizedTable;
