import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, formatDateForReading } from "@mprofit/shared";
import HoldingLayout from "../layout";
import SharedVirtualizedTable, { ColumnDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { HoldingAssetAllocationChart, RowDataProps } from "../holdingAssetAllocationChart";
import { useEffect } from "react";

interface L2_AllStocks_Holding_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L2_AllStocks_Holding(props: L2_AllStocks_Holding_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActiveMFETFPortfolioBreakdownLoading);
  
  return <>
    <RefreshCheck/>
    <HoldingLayout
      {...props}
      Screen_AssetAllocationChart={AssetAllocationChart}
      Screen_Table={HoldingsTable}
      isLoading={isLoading}
      tableHeading={'Stock-wise Details'}
    />
    </>
  ;
}

const RefreshCheck = () => {
  const dispatch = useAppDispatch();
  const activePortfolioIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioIDs);

  useEffect(() => {
    dispatch(SharedRedux.PortfolioSummary.Actions.refreshMFETFPortfolioBreakdown());
  }, [activePortfolioIDs])

  return <></>;
}

const AssetAllocationChart = ({AssetType}: {AssetType?: number}) => {
  const {PieChartData, TotalValue} = useAppSelector((state) => SharedRedux.PortfolioSummary.Selectors.selectL2PieChartData_ByStocksIncludingMFFETFPortBreakdown(state, 'CurrValue'));

  const tableData: RowDataProps[] = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown) as RowDataProps[];

  return <HoldingAssetAllocationChart
    data={PieChartData}
    totalValue={TotalValue}
    dropdownValue={'CurrValue'}
    heading={`Equity Exposure across Stocks, Mutual Funds and ETFs`}
    subHeading={`as on ${formatDateForReading(new Date())}`}
    // onItemClick={onItemClick}
    hideDropdown={true}
    showDownloadPdfIcon={true}
    tableType={SharedConstants.TableType.EquityExposure}
    pdfData={{
      ShowPDFHeading: true,
      ReportNameForFileName: 'Equity Exposure',
      Table:{
        columnData: tableColumns,
        rowData: tableData,
        chartHeading: 'Stock-wise Details'
      }
    }}
  />
}

const HoldingsTable = ({AssetType, searchTerm}: {AssetType?: number, searchTerm: string}) => {
  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable_ByStocksIncludingMFFETFPortBreakdown);

  var fieldNamesToSearch = ['Name'];

  return <SharedVirtualizedTable columns={tableColumns} rows={tableData} headerColor="#EAF4DF" sorting={true} onRowClick={undefined} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch} defaultSortConfig={SharedConstants.DefaultSortingForHoldingTable} tableType={SharedConstants.TableType.EquityExposure} />

}

const tableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'Name',
    HeaderName: 'Stock',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'HoldingPct',
    HeaderName: '% of Equity Exposure',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'PctOfTotal',
    HeaderName: '% of Portfolio',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  }
];