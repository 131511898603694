import { Box, Grid, useTheme } from "@mui/material";
import styles from "./index.module.css";
import DropDown, { DropdownItem } from "../../drop-down";
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";
import CustomTypography from "../../customTypography";
import { Cell, Label, Legend, LegendProps, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { FormatAmountUsingPrefsHelper, SharedConstants, SharedRedux, SharedUtilities, formatDecimal, formatDecimalWithRupeeSymbol, formatPercent } from "@mprofit/shared";
import Big from 'big.js';
import { useNumberFormatPrefs } from "../../../hooks/userPreferencesHooks";
import LeftLegend from "../pie-chart-left-legend/Legends";
import { DownloadPDF } from "../../../download-pdf";
import { CompleteChartPage, HoldingPDFAssetAllocationTotalType, PdfHoldingSnapshot, RowDataProps } from "@mprofit-soft/pdf";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { ColumnDataProps, sortRows } from "../../virtual-table-component";

interface PieChartWithLeftLegendProps {
  data: any[];
  totalValue: string | undefined;
  totalFieldLabel?: string;
  heading: string;
  subHeading: string;
  dropdownValue?: string;
  dropdownList?: DropdownItem[];
  handleDropdownChange?: (selected: DropdownItem) => void,
  onItemClick?: (item: SharedConstants.PieChartData) => void
  showDownloadPdfIcon?: boolean;
  pdfData?: PdfHoldingSnapshotDataProps;
  holdingsMode?: SharedConstants.HoldingsMode;
  tableType: SharedConstants.TableType;
}

interface CustomLabel {
  viewBox: any;
  labelText: string;
  value: string;
}

interface Data {
  name: string;
  value: number;
}

export interface PdfHoldingSnapshotDataProps {
  ShowPDFHeading?: boolean;
  ReportNameForFileName?: string;
  Totals?: SharedConstants.TotalFields;
  Table?: {
    columnData: ColumnDataProps[];
    rowData: RowDataProps[];
    chartHeading: string;
  };
}


const PieChartWithLeftLegend = (props: PieChartWithLeftLegendProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [fontSize, setFontSize] = useState(16);

  const tableConfig = useAppSelector(SharedRedux.Dashboard.Selectors.selectTableConfig(props.tableType));
  const [finalRows, setFinalRows] = useState<RowDataProps[]>( (props.pdfData?.Table?.rowData)! );

  useEffect(() => {
    if (props?.pdfData?.Table) {
      setFinalRows(sortRows(props?.pdfData?.Table?.rowData, props?.pdfData?.Table?.columnData, tableConfig?.sortConfig?.key, tableConfig?.sortConfig?.direction));
    }
  }, [tableConfig, props?.pdfData?.Table])

  const chartRef = useRef<any>();

  const handleMouseLeaveFromPie = () => {
    setActiveIndex(null)
    if (!chartRef?.current) {
      return;
    }
    chartRef.current.setState({
      isTooltipActive: false,
      tooltipPosition: undefined
    });
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.tooltip_outer}>
          <div className={styles.tooltip_container}>
            <div className={styles.tooltip_wrapper}>
              <div className={styles.tooltip_currentPrice}>{formatDecimal(payload[0].payload.numValueBig, 2, 'IN')}</div>
              <p className={styles.tooltip_percentageCont}>{formatPercent(payload[0].value, 1, 'IN')}</p>
            </div>
            <div className={styles.tooltip_loanText}>{payload[0].name}</div>
          </div>
        </div>
      );
    }
    return null
  };

  const theme = useTheme();
  const smBreakpointValue = theme.breakpoints.values.sm;
  const lg1BreakpointValue = theme.breakpoints.values.lg1;

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < smBreakpointValue) {
        setFontSize(12);
      } else if (screenWidth > smBreakpointValue && screenWidth <= lg1BreakpointValue) {
        setFontSize(14);
      } else {
        setFontSize(20);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const CustomLabel = ({ viewBox, labelText, value }: CustomLabel) => {
    const { cx, cy } = viewBox;
    console.log("🚀 ~ file: index.tsx:71 ~ CustomLabel ~  window.innerWidth:", `${fontSize}px`)
    return (
      <g>
        <text
          x={cx}
          y={cy}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fontFamily="Rubik"
          fill="#12131A"
          fontSize={`${fontSize}px`}
          fontWeight="600"
        >
          {value}
        </text>
        <text
          x={cx}
          y={cy + 20}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fill="#64677A"
          fontSize={`${fontSize - 2}px`}
        >
          {" "}
          {labelText}
        </text>
      </g>
    );
  };

  var data = props.data;

  var isEmpty = false;

  if (props.totalValue && Big(props.totalValue).eq(0)) {
    isEmpty = true;
    data = [
      {
        name: 'A',
        value: 1,
        numValueBig: Big(1),
        pctValueBig: Big(1),
        IDs: {},
        color: '#f5f5f6'
      }
    ]
  }

  const numberFormatPrefs = useNumberFormatPrefs();
  const {FormattedValue, IsNumberInLakhsMillions} = FormatAmountUsingPrefsHelper(numberFormatPrefs, props.totalValue, 2);

  return (
    <Box className={styles.piechartWithLeftLegend_wrapper}>
      <Box className={styles.headingContainer}>
        <Box className={styles.headingSubContainer}>
          <Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap={1.5}>
              <CustomTypography variant="Medium20">{props.heading}</CustomTypography>
              {
                props.showDownloadPdfIcon && props.pdfData &&
                <DownloadPDF
                  PDFPage={
                    ({headerProps, overviewProps}) =>
                    <CompleteChartPage
                      headerProps={{
                        ...headerProps,
                        reportType: "Holdings",
                      }}
                      overviewProps={{
                        ...overviewProps,
                        heading: props.pdfData?.ShowPDFHeading ? props.heading : undefined,
                        hidePeriod: true
                      }}
                      ChartComponent={() =>
                        <PdfHoldingSnapshot
                          NumberFormatPrefs={numberFormatPrefs}
                          Totals={props.pdfData?.Totals ? {
                            CurrentValue: props.pdfData.Totals.CurrValue,
                            AmountInvested: props.pdfData.Totals.InvAmt,
                            UnrealisedGain: props.pdfData.Totals.OGain,
                            UnrealisedGainPct: props.pdfData.Totals.OGainPct,
                          } : undefined}
                          PieChart={props?.data ? {
                            data: props.data,
                            totalValue: props.totalValue,
                            legendLayout: "right",
                            chartHeading: props.holdingsMode ? `Holdings by ${SharedUtilities.getHoldingsCategName(props.holdingsMode)}` : undefined,
                            totalType: props.dropdownValue as HoldingPDFAssetAllocationTotalType,
                            width: 270,
                            height: 200,
                          } : undefined}
                          Table={props.pdfData?.Table ? {
                            columnData: props.pdfData.Table.columnData,
                            rowData: finalRows,
                            PMSBadgeField: "IsCashMgmtPortfolio",
                            chartHeading: props.pdfData.Table.chartHeading,
                          } : undefined}
                        />
                      }
                    />
                  }
                  ReportNameForFileName={props.pdfData?.ReportNameForFileName || (props.holdingsMode ? `Holdings by ${SharedUtilities.getHoldingsCategName(props.holdingsMode)}` : 'Holdings')}
                />
              }
            </Box>
            <CustomTypography variant="Regular16" sx={{ color: { xs: "#64677A" } }}>{props.subHeading}</CustomTypography>
          </Box>
        </Box>
        {
          props.dropdownList && props.handleDropdownChange && props.dropdownValue &&
          <Box className={styles.dropDown}>
            <DropDown
              inputValue={props.dropdownValue}
              inputList={props.dropdownList}
              handleChange={props.handleDropdownChange}
            />
          </Box>
        }
      </Box>
      <Grid container className={styles.infoContainer}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%', height: { xs: "300px", md: "280px", lg: "280px", lg1: "310px" } }} onMouseLeave={handleMouseLeaveFromPie}>
            <ResponsiveContainer height='100%' width='100%'>
              <PieChart
                ref={chartRef}
                onMouseLeave={handleMouseLeaveFromPie}
              >
                {
                  !isEmpty &&
                  <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ outline: "none", borderRadius: "10px", zIndex: "1" }}
                  />
                }
                <Pie
                  data={data}
                  innerRadius={"55%"}
                  fill="#8884d8"
                  dataKey="value"
                  cursor={props.onItemClick && !isEmpty ? 'pointer' : 'default'}
                  stroke="none"
                  onMouseEnter={(data, index) => setActiveIndex(index)}
                  onMouseLeave={handleMouseLeaveFromPie}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={entry.color}
                      fillOpacity={!isEmpty && activeIndex === index ? 0.5 : 1}
                      onClick={() => { if (props.onItemClick) props.onItemClick(entry) }}
                    />
                  ))}
                  <Label content={<CustomLabel viewBox labelText={props.totalFieldLabel || ''} value={FormattedValue} />} position="center" />
                </Pie>
                {/* <Tooltip /> */}
                {
                  !isEmpty &&
                  <Legend
                    content={<CustomLegend chartRef={chartRef} setActiveIndex={setActiveIndex} onItemClick={props.onItemClick} />}
                    layout="vertical"
                    verticalAlign="middle"
                    align="left"
                    iconType="circle"
                    iconSize={10}
                    wrapperStyle={{
                      // position: 'relative',
                      // paddingTop: '50px'
                    }}
                  />
                }
              </PieChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const CustomLegend = (customLegendProps: {payload?: SharedConstants.PieChartData[], chartRef: MutableRefObject<any>, setActiveIndex: Dispatch<SetStateAction<number | null>>, onItemClick: ((item: SharedConstants.PieChartData) => void) | undefined}) => {
  const { payload, chartRef, setActiveIndex, onItemClick } = customLegendProps;

  return (
    <LeftLegend data={payload || []} chartRef={chartRef} setActiveIndex={setActiveIndex} onItemClick={onItemClick} />
  );
};

export default PieChartWithLeftLegend;
