import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities, formatDateForReading } from "@mprofit/shared";
import HoldingLayout from "../layout";
import { HoldingCurrentValue } from "../holdingCurrentValue";
import { HoldingAmountInvested } from "../holdingAmountInvested";
import { HoldingUnrealisedGain } from "../holdingUnrealisedGain";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { useHoldingsMode } from "packages/web/src/classes/HoldingsModeHook";
import { HoldingAssetAllocationChart } from "../holdingAssetAllocationChart";
import { useState } from "react";
import { NavigateToAsset, NavigateToL3 } from "packages/web/src/routes/navigationMethods";
import { useNavigate, useLocation } from 'react-router-dom';

interface L3_Holding_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L3_Holding(props: L3_Holding_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);

  return <HoldingLayout
    {...props}
    Screen_CurrentValueCard={CurrentValueCard}
    Screen_AmountInvestedCard={AmountInvestedCard}
    Screen_UnrealisedGainCard={UnrealisedGainCard}
    Screen_AssetAllocationChart={AssetAllocationChart}
    Screen_Table={HoldingsTable}
    isLoading={isLoading}
    tableHeading={'Current Holdings'}
  />;
}

function useHoldingsMode_L3(AssetType?: number) {
  return useHoldingsMode(
      SharedUtilities.getDefaultHoldingsModeForScreen(SharedConstants.ScreenEnum.L3, AssetType),
      SharedUtilities.getHoldingsModesForScreen(SharedConstants.ScreenEnum.L3, AssetType)
    );
}

const CurrentValueCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3PortfolioSummaryTotal);

  return <HoldingCurrentValue
      CurrentValue={totalValues?.CurrValue}
    />
}

const AmountInvestedCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3PortfolioSummaryTotal);

  return <HoldingAmountInvested
      AmountInvested={totalValues?.InvAmt}
    />
}

const UnrealisedGainCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3PortfolioSummaryTotal);

  return <HoldingUnrealisedGain
    OGain={totalValues?.OGain}
    OGainPct={totalValues?.OGainPct}
    OUp={totalValues?.OUp}
  />
}

const AssetAllocationChart = ({AssetType}: {AssetType?: number}) => {
  const [totalField, setTotalField] = useState<SharedConstants.HoldingAssetAllocationDropdownValue>('CurrValue');

  // const holdingsMode = useHoldingsMode_L3(AssetType);
  const {PieChartData, TotalValue} = useAppSelector((state) => SharedRedux.PortfolioSummary.Selectors.selectL3HoldingsPieChartData(state, totalField));

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);

  const L3CategDetails = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3CategDetails);

  const onItemClick = (item: SharedConstants.PieChartData) => {
    if (item && assetTypeID && assetTypeID > 0 && item.IDs.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID, AMID: item.IDs.AMID, SID: item.IDs.SID, pathname: location.pathname });
    }
  }

  const holdingTotals = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL3PortfolioSummaryTotal);
  const tableData: RowDataProps[] = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL3HoldingsTable) as RowDataProps[];

  return <HoldingAssetAllocationChart
    data={PieChartData}
    totalValue={TotalValue}
    dropdownValue={totalField}
    holdingsMode={SharedConstants.HoldingsMode.Assets}
    heading={`${L3CategDetails?.CategName}`}
    subHeading={`as on ${formatDateForReading(new Date())}`}
    handleDropdownChange={(selected) => {setTotalField(selected.id as SharedConstants.HoldingAssetAllocationDropdownValue)}}
    onItemClick={onItemClick}
    showDownloadPdfIcon={true}
    pdfData={{
      Totals: holdingTotals,
      Table:{
        columnData: tableColumns,
        rowData: tableData,
        chartHeading: 'Current Holdings'
      }
    }}
  />
}

const HoldingsTable = ({AssetType, searchTerm}: {AssetType?: number, searchTerm: string}) => {
  const holdingsMode = useHoldingsMode_L3(AssetType);

  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL3HoldingsTable);

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const onRowClick = (row: RowDataProps) => {
    if (row && row.AssetTypeID > 0 && row.AMID > 0) {
      NavigateToAsset({navigate, activeScreenIDs, assetTypeID: row.AssetTypeID, AMID: row.AMID, SID: row.SID, pathname: location.pathname });
    }
  }

  console.log(tableData, 'tableData');

  var fieldNamesToSearch = ['Name'];

  return <SharedVirtualizedTable columns={tableColumns} rows={tableData} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch} defaultSortConfig={SharedConstants.DefaultSortingForHoldingTable} tableType={SharedConstants.TableType.Holding} />

}

const tableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'Name',
    HeaderName: 'Asset',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDefaultFieldFormatter,
    Align: 'left',
    IsRowName: true
  },
  {
    FieldKey: 'InvAmt',
    HeaderName: 'AMOUNT INVESTED',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'HoldingPct',
    HeaderName: 'HOLDING',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  }
];