import { MPrUrlParams } from "packages/web/src/classes/MPrUrlParams";
import { useAppDispatch, useAppSelector } from "packages/web/src/redux/hooks";
import { SharedConstants, SharedRedux, SharedUtilities, formatDateForReading } from "@mprofit/shared";
import HoldingLayout from "../layout";
import { HoldingCurrentValue } from "../holdingCurrentValue";
import { HoldingAmountInvested } from "../holdingAmountInvested";
import { HoldingUnrealisedGain } from "../holdingUnrealisedGain";
import SharedVirtualizedTable, { ColumnDataProps, RowDataProps, TableDecimalFieldFormatter, TableDefaultFieldFormatter, TablePercentFieldFormatter } from "../../../shared/virtual-table-component";
import { useHoldingsMode } from "packages/web/src/classes/HoldingsModeHook";
import { HoldingAssetAllocationChart } from "../holdingAssetAllocationChart";
import { ReactNode, useEffect, useState } from "react";
import { NavigateToAsset, NavigateToAssetType, NavigateToL3Category, NavigateToL3, NavigateToPortfolio, NavigateToTab } from "packages/web/src/routes/navigationMethods";
import { useNavigate, useLocation } from 'react-router-dom';
import AppTour from "../../../shared/app-tour";
import { HOLDING_L2_EQ_TOUR_STEPS, HOLDING_L2_MF_TOUR_STEPS } from "../../../shared/app-tour/appTourSteps";
import { WebRedux } from "@mprofit/web";

interface L2_Holding_Props {
  MPrUrlParams: MPrUrlParams;
}

export default function L2_Holding(props: L2_Holding_Props) {
  const isLoading = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectIsActivePortfolioSummaryLoading);
  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);

  return <HoldingLayout
    {...props}
    Screen_CurrentValueCard={CurrentValueCard}
    Screen_AmountInvestedCard={AmountInvestedCard}
    Screen_UnrealisedGainCard={UnrealisedGainCard}
    Screen_AssetAllocationChart={AssetAllocationChart}
    Screen_Table={HoldingsTable}
    isLoading={isLoading}
    tableHeading={'Current Holdings'}
    Screen_AppTour={HoldingsAppTour}
  />;
}

function useHoldingsMode_L2(AssetType?: number) {
  const assetAllocationCategID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetAllocationCategID);

  var holdingsMode = useHoldingsMode(
    SharedUtilities.getDefaultHoldingsModeForScreen(SharedConstants.ScreenEnum.L2, AssetType),
    SharedUtilities.getHoldingsModesForScreen(SharedConstants.ScreenEnum.L2, AssetType)
    );

  if (assetAllocationCategID && assetAllocationCategID > 0) {
    return SharedConstants.HoldingsMode.Assets;
  } else {
    return holdingsMode;
  }
}

const CurrentValueCard = () => {
  const currentValue = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL2PortfolioSummaryTotalCurrentValue);

  return <HoldingCurrentValue
      CurrentValue={currentValue}
    />
}

const AmountInvestedCard = () => {
  const amountInvested = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL2PortfolioSummaryTotalAmountInvested);

  return <HoldingAmountInvested
      AmountInvested={amountInvested}
    />
}

const UnrealisedGainCard = () => {
  const totalValues = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL2PortfolioSummaryTotal);

  return <HoldingUnrealisedGain
    OGain={totalValues?.OGain}
    OGainPct={totalValues?.OGainPct}
    OUp={totalValues?.OUp}
  />
}

const AssetAllocationChart = ({AssetType}: {AssetType?: number}) => {
  const [totalField, setTotalField] = useState<SharedConstants.HoldingAssetAllocationDropdownValue>('CurrValue');

  const holdingsMode = useHoldingsMode_L2(AssetType);
  const {PieChartData, TotalValue} = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsPieChartData(holdingsMode, totalField, AssetType));

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const assetTypeIDName = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetTypeIDName);
  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);

  const onItemClick = (item: SharedConstants.PieChartData) => {
    console.log('onRowClick', item);
    if (item && assetTypeID && assetTypeID > 0) {
      if (holdingsMode === SharedConstants.HoldingsMode.Assets && item.IDs.AMID > 0) {
        NavigateToAsset({navigate, activeScreenIDs, assetTypeID: assetTypeID, AMID: item.IDs.AMID, SID: item.IDs.SID, pathname: location.pathname });
      } else if (holdingsMode === SharedConstants.HoldingsMode.Sectors && item.IDs.CategID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SectorID: item.IDs.CategID, L3Mode: SharedConstants.HoldingsMode.Sectors, pathname: location.pathname})
      } else if (holdingsMode === SharedConstants.HoldingsMode.MarketCap && item.IDs.CategID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, MarketCapID: item.IDs.CategID, L3Mode: SharedConstants.HoldingsMode.MarketCap, pathname: location.pathname})
      } else if (holdingsMode === SharedConstants.HoldingsMode.MFSEBICategory && item.IDs.CategID != undefined) {
        NavigateToL3Category({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SEBICategoryID: item.IDs.CategID, L3Mode: SharedConstants.HoldingsMode.MFSEBICategory, pathname: location.pathname})
      } else if (holdingsMode == SharedConstants.HoldingsMode.MFSEBISubCategory && item.IDs.CategID != undefined && item.IDs.SEBICategoryID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SEBICategoryID: item.IDs.SEBICategoryID, SEBISubCategoryID: item.IDs.CategID, L3Mode: SharedConstants.HoldingsMode.MFSEBISubCategory, pathname: location.pathname })
      }
    }
  }

  const holdingTotals = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveL2PortfolioSummaryTotal);
  const tableData: RowDataProps[] = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable(holdingsMode)) as RowDataProps[];

  return <HoldingAssetAllocationChart
    data={PieChartData}
    totalValue={TotalValue}
    dropdownValue={totalField}
    holdingsMode={holdingsMode}
    heading={`${assetTypeIDName?.AssetTypeName}`}
    subHeading={`as on ${formatDateForReading(new Date())}`}
    handleDropdownChange={(selected) => {setTotalField(selected.id as SharedConstants.HoldingAssetAllocationDropdownValue)}}
    onItemClick={onItemClick}
    showDownloadPdfIcon={true}
    pdfData={{
      Totals: holdingTotals,
      Table:{
        columnData: getColumns(holdingsMode, assetTypeID || 0),
        rowData: tableData,
        chartHeading: 'Current Holdings'
      }
    }}
  />
}

const HoldingsTable = ({AssetType, searchTerm}: {AssetType?: number, searchTerm: string}) => {
  const holdingsMode = useHoldingsMode_L2(AssetType);

  const tableData = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectL2HoldingsTable(holdingsMode));

  const navigate = useNavigate();
  const location = useLocation();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const assetTypeID = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveAssetType);

  if (!assetTypeID) return <></>;

  const onRowClick = (row: RowDataProps) => {
    console.log('onRowClick', row);
    if (row && assetTypeID && assetTypeID > 0) {
      if (holdingsMode == SharedConstants.HoldingsMode.Assets && row.AMID > 0) {
        NavigateToAsset({navigate, activeScreenIDs, assetTypeID: assetTypeID, AMID: row.AMID, SID: row.SID, pathname: location.pathname });
      } else if (holdingsMode == SharedConstants.HoldingsMode.Sectors && row.CategID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SectorID: row.CategID, L3Mode: SharedConstants.HoldingsMode.Sectors, pathname: location.pathname})
      } else if (holdingsMode == SharedConstants.HoldingsMode.MarketCap && row.CategID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, MarketCapID: row.CategID, L3Mode: SharedConstants.HoldingsMode.MarketCap, pathname: location.pathname})
      } else if (holdingsMode == SharedConstants.HoldingsMode.MFSEBICategory && row.CategID != undefined) {
        NavigateToL3Category({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SEBICategoryID: row.CategID, L3Mode: SharedConstants.HoldingsMode.MFSEBICategory, pathname: location.pathname})
      } else if (holdingsMode == SharedConstants.HoldingsMode.MFSEBISubCategory && row.CategID != undefined && row.SEBICategoryID != undefined) {
        NavigateToL3({ navigate, activeScreenIDs, assetTypeID: assetTypeID, SEBICategoryID: row.SEBICategoryID, SEBISubCategoryID: row.CategID, L3Mode: SharedConstants.HoldingsMode.MFSEBISubCategory, pathname: location.pathname })
      }
    }
  }

  console.log(tableData, 'tableData');

  var fieldNamesToSearch = [holdingsMode == SharedConstants.HoldingsMode.Assets ? 'Name' : 'CategName'];

  return <SharedVirtualizedTable columns={getColumns(holdingsMode, assetTypeID)} rows={tableData} headerColor="#EAF4DF" sorting={true} onRowClick={onRowClick} searchTerm={searchTerm} fieldNamesToSearch={fieldNamesToSearch} defaultSortConfig={SharedConstants.DefaultSortingForHoldingTable} tableType={SharedConstants.TableType.Holding} />

}

const commonTableColumns: ColumnDataProps[] = [
  {
    FieldKey: 'InvAmt',
    HeaderName: 'AMOUNT INVESTED',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'OGain',
    HeaderName: 'UNREALISED GAIN',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
    subColumns: [
      {
        FieldKey: 'OGainPct',
        FieldFormatter: TablePercentFieldFormatter,
        UpFieldKey: 'OUp'
      }
    ]
  },
  {
    FieldKey: 'CurrValue',
    HeaderName: 'CURRENT VALUE',
    ColumnWidthPercent: '25%',
    FieldFormatter: TableDecimalFieldFormatter,
    Align: 'right',
  },
  {
    FieldKey: 'HoldingPct',
    HeaderName: 'HOLDING',
    ColumnWidthPercent: '25%',
    FieldFormatter: TablePercentFieldFormatter,
    Align: 'right',
  }
];

export const getColumns = (holdingsMode: SharedConstants.HoldingsMode, assetTypeID: SharedConstants.AssetTypeEnum, headerName: string | undefined = undefined, headerFieldKey: string | undefined = undefined): ColumnDataProps[] => {
  return [
    {
      FieldKey: headerFieldKey || (holdingsMode == SharedConstants.HoldingsMode.Assets ? 'Name' : 'CategName'),
      HeaderName: headerName || (SharedUtilities.getHoldingsCategName(holdingsMode)),
      ColumnWidthPercent: '25%',
      FieldFormatter: TableDefaultFieldFormatter,
      Align: 'left',
      IsRowName: true
    },
    ...(
      holdingsMode == SharedConstants.HoldingsMode.Assets && assetTypeID < SharedConstants.AssetTypeEnum.ASSET_WITH_QUANT
      ?
      [
        {
          FieldKey: 'Quant',
          HeaderName: "QTY",
          ColumnWidthPercent: '13%',
          FieldFormatter: TableDecimalFieldFormatter,
          Align: 'right'
        },
        {
          FieldKey: 'PxPur',
          HeaderName: "AVG PUR PRICE",
          ColumnWidthPercent: '13%',
          FieldFormatter: TableDecimalFieldFormatter,
          Align: 'right'
        },
        {
          FieldKey: 'PxCurr',
          HeaderName: "CURRENT PRICE",
          ColumnWidthPercent: '13%',
          FieldFormatter: TableDecimalFieldFormatter,
          Align: 'right'
        }
      ]
      :
      ([] as any[])
    ),
    ...commonTableColumns
  ]
}

const HoldingsAppTour = ({children}: {children: ReactNode}) => {
  const startHoldingTour = useAppSelector(WebRedux.Tutorial.Selectors.selectStartHoldingTour);
  const [runAppTour, setRunAppTour] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (startHoldingTour) {
      setRunAppTour(true);
      dispatch(WebRedux.Tutorial.Actions.toggleHoldingTour(false));
    }
  }, [startHoldingTour]);

  const navigate = useNavigate();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);

  const assetTypeList = useAppSelector(SharedRedux.PortfolioSummary.Selectors.selectActiveAssetTypesList);
  const activePortfolioIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioIDs);

  if (![SharedConstants.AssetTypeEnum.EQ, SharedConstants.AssetTypeEnum.MFEQ].includes(activeScreenIDs?.AssetType || 0)) {
    return <>{children}</>;
  }

  const onTourEnd = () => {
    var goToPerformance = true;
    if (assetTypeList) {
      if (activeScreenIDs?.AssetType === SharedConstants.AssetTypeEnum.EQ && assetTypeList.find(x => x.AssetTypeID === SharedConstants.AssetTypeEnum.MFEQ)) {
        NavigateToAssetType({navigate, activeScreenIDs, assetTypeID: SharedConstants.AssetTypeEnum.MFEQ, dashboardTab: SharedConstants.DashboardTabs.HOLDING});
        dispatch(WebRedux.Tutorial.Actions.toggleHoldingTour(true));
        goToPerformance = false;
      }
    }

    if (goToPerformance) {
      NavigateToPortfolio({navigate, dashboardTab: SharedConstants.DashboardTabs.PERFORMANCE, activePortfolio: activePortfolioIDs});
      dispatch(WebRedux.Tutorial.Actions.togglePerformanceTour(true));
    }
  }

  console.log('runAppTour3',runAppTour,startHoldingTour)

  return <AppTour
      runAppTour={runAppTour}
      setRunAppTour={setRunAppTour}
      appTourSteps={activeScreenIDs?.AssetType === SharedConstants.AssetTypeEnum.EQ ? HOLDING_L2_EQ_TOUR_STEPS : HOLDING_L2_MF_TOUR_STEPS}
      onTourEnd={onTourEnd}
    >
    {children}
  </AppTour>
}