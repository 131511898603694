import { AssetAllocationCategID } from "./assetMasters";
import { AssetTypeEnum } from "./assetTypes";
import Big from 'big.js';

export interface DBPortfolioSummaryRaw {
    [FamilyId: number]: FamilyPortfolioSummaryRaw
    
}
export interface FamilyPortfolioSummaryRaw {
    [PFID: number]: PortfolioSummaryRaw
}

export interface PortfolioSummaryRaw {
    PortfolioId: number;
    PeriodFrom?: Date;
    TargetAmount?: number;
    FamilyId: number;
    PFolioType: number;
    SumAssetTypes: SumAssetTypeRaw[];
}

export interface SumAssetTypeRaw {
    SeqNo: number;
    AssetTypeID: number;
    Assets: AssetRaw[];
}

export interface AssetRaw {
    SID: number;
    PFID: number;
    AMID: number;
    Name: string;
    Refno: string;
    TransCount: number;
    Quant: number;
    InvAmt: number;
    TGain: number;
    CurrValue: number;
    CurrPrice: number;
    RelGain: number;
    Today_AmtInv: number;
    Today_Quant: number;
    Exp_Month: number;
    Exp_Year: number;
    IsCurrVManual: boolean;
}

export interface ActivePortfolioSummary {
    CCID: number;
    FamilyId: number;
    PFID: number;
    AssetAllocationCategID?: AssetAllocationCategID;
    AssetsByAssetType: AssetsByAssetType;
    AssetTypeTotals: AssetTypeTotals;
    PortfolioTotal: PortfolioTotal;
    AssetsForSearch: Array<{id: string, text: string}>;
    IsGroup: boolean;
}

export interface AssetsByAssetType {
    [AssetTypeID: number]: SIDAssetTotals;
}

export interface SIDAssetTotals {
    ByID: {
        [SID: number]: AssetTotal;
    },
    AllIDs: number[];
}

export interface AssetTypeTotals {
    ByID: {
        [AssetTypeID: number]: AssetTypeTotal;
    },
    AllIDs: AssetTypeEnum[];
}

export interface TotalFields {
    Refno?: string;
    TransCount?: number;
    Quant?: string;
    InvAmt?: string;
    PxPur?: string;
    PxCurr?: string;
    PxPrev?: string;
    CurrValue?: string;
    PreviousValueForTGainPct?: string;
    IsCurrVManual?: boolean;
    IsOrigCurrValueNonZero?: boolean;
    TGain?: string;
    OGain?: string;
    TGainPct?: string;
    OGainPct?: string;
    TUp?: boolean;
    OUp?: boolean;
    TChange?: boolean;
    OChange?: boolean;
    HoldingPct?: string;
    HoldingPctValue?: number;
}

export interface TotalFields_Big {
    TransCount?: number;
    Quant?: Big;
    InvAmt?: Big;
    PxPur?: Big;
    PxCurr?: Big;
    PxPrev?: Big;
    CurrValue?: Big;
    PreviousValueForTGainPct?: Big;
    IsCurrVManual?: boolean;
    IsOrigCurrValueNonZero?: boolean;
    TGain?: Big;
    OGain?: Big;
    TGainPct?: Big;
    OGainPct?: Big;
    TUp?: boolean;
    OUp?: boolean;
    TChange?: boolean;
    OChange?: boolean;
}

export interface HoldingTableFields extends TotalFields {
    HoldingPctValue?: number;
    HoldingPct?: string;
    PctOfTotalValue?: number;
    PctOfTotal?: string;
}

export interface AssetFields {
    SID: number;
    PFID: number;
    AMID: number;
    AssetTypeID: AssetTypeEnum;
    Name: string;
    Refno?: string;
}

export interface AssetTotal extends TotalFields, AssetFields { }

export interface AssetTotal_Big extends TotalFields_Big, AssetFields { }

export interface AMIDAssetFields {
    PFID: number;
    AMID: number;
    AssetTypeID: AssetTypeEnum;
    Name: string;
    SID?: number;
}

export interface AMIDAssetTotal extends TotalFields, AMIDAssetFields { }

export interface AMIDAssetTotal_Big extends TotalFields_Big, AMIDAssetFields { }

export interface AssetTypeTotal extends TotalFields {
    AssetTypeID: number;
    SeqNo: number;
    AssetTypeName: string;
}

export interface AssetAllocationCategTotals {
    ByID: {
        [AssetAllocationCategID: number]: AssetAllocationCategTotal;
    };
    AllIDs: AssetAllocationCategID[];
}

export interface AssetAllocationCategTotals_Big {
    ByID: {
        [AssetAllocationCategID: number]: AssetAllocationCategTotal_Big;
    },
    AllIDs: AssetAllocationCategID[];
}

export interface AssetAllocationCategTotal extends TotalFields {
    AssetAllocationCategID: AssetAllocationCategID;
    AssetAllocationCategName: string;
}

export interface AssetAllocationCategTotal_Big extends TotalFields_Big {
    AssetAllocationCategID: AssetAllocationCategID;
    AssetAllocationCategName: string;
}

export interface HoldingsCategTotals {
    ByID: {
        [ID: string | number]: HoldingCategTotal;
    },
    AllIDs: (string | number)[];
}

export interface HoldingsCategTotals_Big {
    ByID: {
        [ID: string | number]: HoldingCategTotal_Big;
    },
    AllIDs: (string | number)[];
}

export interface HoldingCategTotal extends TotalFields {
    CategID: string | number;
    CategName: string;
}

export interface HoldingCategTotal_Big extends TotalFields_Big {
    CategID: string | number;
    CategName: string;
}

export interface PortfolioTotal extends TotalFields { }

export interface PriceData {
    IsRefreshedOnce?: boolean;
    PriceURLs?: {
        [AssetTypeID: number]: string[]
    },
    PriceValues?: PriceValues;
}

export interface PriceValues {
    [AssetTypeID: number]: PriceValuesByAMID
}

export interface PriceValuesByAMID {
    [AMID: string]: {
        CP: string,
        PP: string
    }
}

export interface StockAddInfo {
    ParentMProfitCode: number;
    SubCategoryType: number;
    AssetAllocationCategID: number;
    AssetAllocationSubCategID: number;
    MarketCapClassification: number
    SectorID: number;
    SectorName: string;
    MProfitCode: number;
    CompanyName: string;
    BSECode: number;
    NSESymbol: string;
    BSESymbol: string;
    ISIN: string;
}

export interface StockAddInfoByAMID {
    [AMID: number]: StockAddInfo;
}

export interface AssetAllocationInfo {
    MProfitCode: number;
    AssetType: number;
    AssetAllocationCategID: number;
    AssetAllocationSubCategID: number;
}

export interface AssetAllocationInfoByAMID {
    [AMID: number]: AssetAllocationInfo;
}

export interface MFAddInfo {
    SEBICategory: string;
    SubCategoryName: string;
    IsActive: boolean;
    MProfitCode: number;
    AssetAllocationCategID: number;
    AssetAllocationSubCategID: number;
}

export interface MFAddInfoByAMID {
    [AMID: number]: MFAddInfo;
}

export interface AssetsAddInfo {
    Stocks?: StockAddInfoByAMID;
    MF?: MFAddInfoByAMID;
    Others?: {[ATTY: number]: AssetAllocationInfoByAMID}
}

export interface AssetsAddInfoRaw {
    Stocks?: StockAddInfo[];
    MF?: MFAddInfo[];
    Others?: AssetAllocationInfo[];
}


// export enum SummaryViewType {
//     Assets = 1,
//     Sectors = 2,
//     MarketCap = 3,
//     AssetTypes = 4,
//     AssetAllocation = 5
// }

export enum HoldingsMode {
    Undefined = 0,
    AssetAllocation = 1,
    MProfitClassification = 2,
    Assets = 3,
    Sectors = 4,
    MarketCap = 5,
    MFSEBICategory = 6,
    MFSEBISubCategory = 7
}

export type HoldingAssetAllocationDropdownValue = 'CurrValue' | 'InvAmt';
