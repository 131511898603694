import { StyleSheet } from "@react-pdf/renderer";

export const commonStyle = StyleSheet.create({
  cardBorder: {
    border: "1px solid #D8DAE5",
    borderRadius: "8px",
    paddingTop: "20px",
  },
  heading: {
    fontSize: "16px",
    color: "#12131A",
    letterSpacing: 0.23,
    fontWeight: 500,
    marginBottom: "10px",
    paddingBottom: "20px",
    paddingHorizontal: "16px",
    borderBottom: "1px solid #D8DAE5",
  },
  pieChartHeading: {
    fontSize: "14px",
    color: "#12131A",
    letterSpacing: 0.23,
    fontWeight: 500
  },
  justifySpaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  divider: {
    backgroundColor: "#D8DAE5",
    height: "1px",
    width: "100%",
  },
  verticalDivider: {
    backgroundColor: "#D8DAE5",
    width: "1px",
    // height: "100%",
  },
  threeColCardView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "29px 25px",
    border: "1px solid #D8DAE5",
    marginTop: 24,
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    height: "113px",
  },
  numberWithDividerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "flex-start",
  },
  infoHeading: {
    fontSize: 14,
    color: "#12131A",
    fontWeight: 500,
  },
  infoSubHeading: {
    fontSize: 14,
    color: "#64677A",
  },
});
